// In every file you need this!

import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;

import 'owl.carousel';
import 'animate.css';

if ($(".services")[0]) {
    $(document).ready(function () {
        var carousel = $(".services .owl-carousel");
        carousel.owlCarousel({
            //autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            loop: true,
            // dots: true,
            // nav: false,
            // stagePadding: 0,
            // margin: 0, 
            responsiveClass: true, 
            responsive: { 
            0: {
                items: 1
            },
            1024: { 
                items: 2
            }
          }
        });
        $(".services .owl_nav_services_carousel").on("click", function() {
        if ($(this).hasClass("prev")) {
                $(".services .owl-carousel button.owl-prev").trigger("click");
                // console.log("clicked pre");
            } else if ($(this).hasClass("next")) {
                $(".services .owl-carousel button.owl-next").trigger("click");
                // console.log("clicked next");
            } 
        });
    });
}

if ($(".gallery")[0]) {
    $(document).ready(function () {
        var carousel = $(".gallery .owl-carousel");
        carousel.owlCarousel({
            //autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            loop: true,
            // dots: true,
            // nav: false,
            // stagePadding: 0,
            // margin: 0, 
            responsiveClass: true, 
            responsive: {
            0: {
                items: 1
            },
            1025: {  
                items: 4
            }
          }
        });
        $(".gallery .owl_nav_gallery_carousel").on("click", function() {
        if ($(this).hasClass("prev")) {
                $(".gallery .owl-carousel button.owl-prev").trigger("click");
                // console.log("clicked pre");
            } else if ($(this).hasClass("next")) {
                $(".gallery .owl-carousel button.owl-next").trigger("click");
                // console.log("clicked next");
            } 
        });
    });
}

if ($(".gallery_two")[0]) {
    $(document).ready(function () {
        var carousel = $(".gallery_two .owl-carousel");
        carousel.owlCarousel({
            //autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            loop: true,
            // dots: true,
            // nav: false,
            // stagePadding: 0,
            // margin: 0, 
            responsiveClass: true, 
            responsive: {
            0: {
                items: 1
            },
            1025: {  
                items: 2
            }
          }
        });
        $(".gallery_two .owl_nav_gallery_carousel").on("click", function() {
        if ($(this).hasClass("prev")) {
                $(".gallery_two .owl-carousel button.owl-prev").trigger("click");
                // console.log("clicked pre");
            } else if ($(this).hasClass("next")) {
                $(".gallery_two .owl-carousel button.owl-next").trigger("click");
                // console.log("clicked next");
            } 
        });
    });
}

// if ($(".testimonial")[0]) {
//     $(document).ready(function () {
//         var carousel = $(".testimonial .owl-carousel");
//         carousel.owlCarousel({
//             autoplay: true,
//             loop:true,
//             nav:false,
//             items:1,
//             autoplayTimeout: 10000, 
//             center:true,
//             responsive:{
//                 0: { 
//                 items: 1,
//                 }
//             }
//         });
//     });
// }


// if ($(".companies")[0]) {
//     $(document).ready(function () {
//         var carousel = $(".companies .owl-carousel");
//         carousel.owlCarousel({
//             autoplay: true,
//             autoplayTimeout: 5000,
//             autoplayHoverPause: true,
//             loop: true,
//             dots: true,
//             nav: false, 
//             responsiveClass: true,
//             responsive: {
//             0: {
//                 items: 2
//             },
//             767: {
//                 items: 3
//             },
//             992: { 
//                 items: 4
//             },
//             1025: { 
//                 items: 5
//             }
//           }
//         });
//         $(".companies .owl_nav_companies_carousel").on("click", function() {
//         if ($(this).hasClass("prev")) {
//                 $(".owl-carousel button.owl-prev").trigger("click");
//                 // console.log("clicked pre");
//             } else if ($(this).hasClass("next")) {
//                 $(".owl-carousel button.owl-next").trigger("click");
//                 // console.log("clicked next");
//             } 
//         });
//     });
// }



// if ($(".subpages")[0]) {
//     $(document).ready(function () {
//         var carousel = $(".subpages .owl-carousel");
//         carousel.owlCarousel({
//             autoplay: true,
//             autoplayTimeout: 5000,
//             autoplayHoverPause: true,
//             loop: true,
//             dots: true,
//             nav: false,
//             stagePadding: 50,
//             margin: 80, 
//             responsiveClass: true, 
//             responsive: {
//             0: {
//                 items: 1,
//                 stagePadding: 0,
//                 margin: 0
//             },
//             767: {
//                 items: 2,
//                 stagePadding: 10,
//                 margin: 10
//             },
//             1025: { 
//                 items: 3
//             }
//           }
//         });
//         $(".subpages .owl_nav_subpages_carousel").on("click", function() {
//         if ($(this).hasClass("prev")) {
//                 $(".subpages .owl-carousel button.owl-prev").trigger("click");
//                 // console.log("clicked pre");
//             } else if ($(this).hasClass("next")) {
//                 $(".subpages .owl-carousel button.owl-next").trigger("click");
//                 // console.log("clicked next");
//             } 
//         });
//     });
// }

// if ($(".testimonial")[0]) {
//     $(document).ready(function () {
//         var carousel = $(".testimonial .owl-carousel");
//         carousel.owlCarousel({
//            stagePadding: 200,
//             autoplay: true,
//             loop:true,
//             margin:10,
//             nav:false,
//             items:1,
//             lazyLoad: true,
//             responsive:{
//                 0:{
//                     items:1,
//                     stagePadding: 0
//                 },
//                 600:{
//                     items:1,
//                     stagePadding: 50
//                 },
//                 1000:{
//                     items:1,
//                     stagePadding: 150
//                 },
//                 1200:{
//                     items:1,
//                     stagePadding: 200
//                 },
//                 1400:{
//                     items:1,
//                     stagePadding: 250
//                 },
//                 1600:{
//                     items:1,
//                     stagePadding: 300
//                 },
//                 2000:{
//                     items:1,
//                     stagePadding: 350
//                 }
//             }
//         });
//         $(".testimonial .owl_nav_testimonial_carousel").on("click", function() {
//         if ($(this).hasClass("prev")) {
//                 $(".testimonial .owl-carousel button.owl-prev").trigger("click");
//                 // console.log("clicked pre");
//             } else if ($(this).hasClass("next")) {
//                 $(".testimonial .owl-carousel button.owl-next").trigger("click");
//                 // console.log("clicked next");
//             } 
//         });
//     });
// }



// let carousels = document.getElementsByClassName('owl-carousel');

// if( carousels.length > 0){
//     [...carousels].forEach( carousel => {
//         if( carousel.classList.contains('testimonials')){

//             // let carouselClassChange = function () {
//             //     let carouselStage = carousel.getElementsByClassName('owl-stage')[0];
//             //     console.log(carouselStage);
//             //     let activeSlides = carouselStage.getElementsByClassName('active');
//             //     console.log(activeSlides);
//             //     // [...activeSlides].forEach( slide => {
//             //     //     slide.classList.remove('middle-slide'); 
//             //     // })
//             //     // activeSlides[1].classList.add('middle-slide');
//             // }

//             let config = { attributes: true, childList: true, subtree: true };
//             let observer = new MutationObserver( carouselClassChange );
//             observer.observe( carousel, config );
//         }
//     })

// }


// if ($(".accreditation")[0]) {
//     $(document).ready(function () {
//         var carousel = $(".accreditation .owl-carousel");
//         carousel.owlCarousel({
//             autoplay: true,
//             autoplayTimeout: 5000,
//             autoplayHoverPause: true,
//             loop: true,
//             dots: true,
//             nav: false, 
//             responsiveClass: true,
//             responsive: {
//             0: {
//                 items: 1
//             },
//             767: {
//                 items: 2
//             },
//             992: { 
//                 items: 4
//             },
//             1025: { 
//                 items: 5
//             }
//           }
//         });
//         $(".accreditation .owl_nav_accreditation_carousel").on("click", function() {
//         if ($(this).hasClass("prev")) {
//                 $(".owl-carousel button.owl-prev").trigger("click");
//                 // console.log("clicked pre");
//             } else if ($(this).hasClass("next")) {
//                 $(".owl-carousel button.owl-next").trigger("click");
//                 // console.log("clicked next");
//             } 
//         });
//     });
// }



// if ($(".banner_slider")[0]) {
//     $(document).ready(function () {
//         var carousel = $(".banner_slider .owl-carousel");
//         carousel.owlCarousel({
//             autoplay: true,
//             autoplayTimeout: 6000,
//             autoplayHoverPause: true,
//             loop: true,
//             dots: true,
//             items: 1
//         });
//     });
// } 

// Top home carousel
// if ($(".banner_slider")[0]) {
//     $(document).ready(function () {
//         var carousel = $(".banner_slider .owl-carousel");
//         carousel.owlCarousel({
//             autoplay: true,
//             autoplayTimeout: 5000,
//             autoplayHoverPause: true, 
//             loop: true,
//             dots: true, 
//             items: 1,
//             animateOut: 'slideOutUp',
//             animateIn: 'slideInUp',
//             mouseDrag: false,
//             touchDrag: false,
//             pullDrag: false,
//             freeDrag: false
//         });
//     }); 
// }



// Gallery carousel with custom navigation
// if ($(".testimonials_carousel")[0]) {
// 	$(document).ready(function() {
// 		var carousel = $(".testimonials_carousel .owl-carousel");
// 		carousel.owlCarousel({
// 		  loop: true,
// 		  nav: true,
// 		  items: 1
// 		});
// 	});

// 	$(".owl_nav_team_carousel").on("click", function() {
// 		if ($(this).hasClass("prev")) {
// 			$(".testimonials_carousel button.owl-prev").trigger("click");
// 		} else if ($(this).hasClass("next")) {
// 			$(".testimonials_carousel button.owl-next").trigger("click");
// 		}
// 	});
// }

