// In every file you need this!

import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;

// Recite me
$(".reciteme").click(function(i,e){
  loadService();
  return false;
});


// Sticky Header 

$(window).scroll(function() {    
  var scroll = $(window).scrollTop();
  if ($(window).width() > 991) {
    console.log("Is desktop");
    if (scroll > 0) {
      $(".header").addClass("sticky_header");
    } else {
      $(".header").removeClass("sticky_header");
    }
  }
});

//NAV TOGGLES 
const navigation = document.getElementById('navigation_wrapper'); 
const burgerMenu = document.getElementById('burger_menu_btn');
const icons = document.querySelectorAll('.hamburger');

burgerMenu.addEventListener('click', (event) => {
  //NAV ANIMATION
  for (var i = 0; i < icons.length; i++) {
    icons[i].classList.toggle("open");
  }
	//MENU TOGGLE
    navigation.classList.toggle("open");
    burgerMenu.classList.toggle("open");
});

$('p:empty').remove();

// Scroll to

$("#schools_btn").click(function() {
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#schools_form").offset().top  
    }, 1000);
});


$(".sub_navigation_desktop .sub_nav_btn").on("click", function() {
  var class_to_show = $(this).attr("data-attr");

  $([document.documentElement, document.body]).animate({
      scrollTop: $(".sub_navigation_desktop").offset().top - 86
  }, 1000);


  $(".sub_nav_btn").removeClass("active_nav");
  $(this).addClass("active_nav");

  $(".content_show").removeClass("active");
  $(".content_show").removeClass("fadeIn");
  $("." + class_to_show).addClass("active"); 
  $("." + class_to_show).addClass("fadeIn");
  
});

$(".sub_navigation_mobile_one .sub_nav_btn").on("click", function() {
  var class_to_show = $(this).attr("data-attr");

  $([document.documentElement, document.body]).animate({
      scrollTop: $(".sub_navigation_mobile_one").offset().top - 100
  }, 1000);


  $(".sub_nav_btn").removeClass("active_nav");
  $(this).addClass("active_nav");

  $(".content_show").removeClass("active");
  $(".content_show").removeClass("fadeIn");
  $("." + class_to_show).addClass("active"); 
  $("." + class_to_show).addClass("fadeIn");
  
});

$(".close_button").on("click", function() {
  var class_to_show = $(this).attr("data-attr");
  $(".content_show").removeClass("active");
});


$(document).ready(function() {
  $(".tab_inner").on("click", function() {
    console.log("Clicked tab");
    $(this).next(".content").toggleClass("active"); 
    $(this).find(".arrow").toggleClass("turn_arrow");  
  });
});

// Set Random Colors

var colors = ['#00abaa', '#25303b', '#005B67'];

$(".events_information").each(function( index ) {
  var random_color = colors[Math.floor(Math.random() * colors.length)];
  if ( !$(this).hasClass("dark_blue") || !$(this).hasClass("turquoise") || !$(this).hasClass("dark_green") ) {
    $(this).css('background', random_color);
  }
});

$(".step_one").on("click", function() {
  $(".form_section_two").addClass("open");
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".form_section_two").offset().top - 100
  }, 1000);
});

$(".step_two").on("click", function() {
  $(".form_section_three").addClass("open");
  $([document.documentElement, document.body]).animate({
      scrollTop: $(".form_section_three").offset().top - 100
  }, 1000);
});

$(".step_three").on("click", function() {
  $(".form_section_four").addClass("open");
   $([document.documentElement, document.body]).animate({
      scrollTop: $(".form_section_four").offset().top - 100
  }, 1000);
});

$(".step_four").on("click", function() {
  $(".form_section_five").addClass("open");
  $([document.documentElement, document.body]).animate({
      scrollTop: $(".form_section_five").offset().top - 100
  }, 1000);
});

$(".step_five").on("click", function() {
  $(".form_section_six").addClass("open");
   $([document.documentElement, document.body]).animate({
      scrollTop: $(".form_section_six").offset().top - 100
  }, 1000);
});

$(".step_six").on("click", function() {
  $(".form_section_seven").addClass("open");
   $([document.documentElement, document.body]).animate({
      scrollTop: $(".form_section_seven").offset().top - 100
  }, 1000);
});

$(".gform_button").on("click", function() {
  $(this).find(".form_section_two").addClass("open");
});

$(".policie_category").on("click", function() {
  var class_to_show = $(this).attr("data-attr");

  $(".policie_category").removeClass("active");
  $(this).addClass("active");

  $(".hidden_content_section").removeClass("active");
  $(".hidden_content_section").removeClass("fadeIn");
  $("." + class_to_show).addClass("active"); 
  $("." + class_to_show).addClass("fadeIn");
  
});

$(document).ready(function() {
  if (window.location.href.indexOf("?section=") > -1) {
    if (window.location.href.indexOf("?section=disclaimer") > -1) {
      $(".disclaimer_btn").addClass("active");
      $(".disclaimer").addClass("active"); 
      $(".disclaimer").addClass("fadeIn");
    }
    if (window.location.href.indexOf("?section=cookie_policy") > -1) {
      $(".cookie_btn").addClass("active");
      $(".cookie_policy").addClass("active"); 
      $(".cookie_policy").addClass("fadeIn");
    }
    if (window.location.href.indexOf("?section=terms_conditions") > -1) {
      $(".terms_btn").addClass("active");
      $(".terms_conditions").addClass("active"); 
      $(".terms_conditions").addClass("fadeIn");
    }
    if (window.location.href.indexOf("?section=accessibility") > -1) {
      $(".accessibility_btn").addClass("active");
      $(".accessibility").addClass("active"); 
      $(".accessibility").addClass("fadeIn");
    }
  } else {
    $(".disclaimer_btn").addClass("active");
    $(".disclaimer").addClass("active");
  }
});